import { Component, OnInit } from '@angular/core';
import {AnimationItem, LottieOptions} from 'ngx-lottie';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    options: LottieOptions = {
        path: '/assets/'
    };

    public animationCreated(animationItem: AnimationItem): void {
        console.log(animationItem);
    }

  constructor() { }

  ngOnInit() {
  }

}
