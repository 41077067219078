import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
    team = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

    constructor() {
    }

    ngOnInit() {
    }

}
