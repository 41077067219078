/* tslint:disable:max-line-length */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    token: 'pk.eyJ1IjoidHJhb3JlIiwiYSI6ImNqcnEyZG0zbTFnbzc0N21mOHY3aGxidnQifQ.80Omoz8CBEMbTjo13mm7Ug',
    apiToken: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NjIwNTYzMDV9.M8gQ8Qu4u5kpiUZIszFSncEFVcn_dkt7NhY33eT-smZWD3yX9R71-qDofUI0PwZFBMERjnGjTLijNSnhWpvgrA',
    apiDevToken: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NjIwNTYzMDV9.HWi7Yt3nc-83Vm_OsqsSU9MvV14IzJPzMULFYQDqJDJz5dmb1a_nx0qyci2jMHQgMIvM5a5ZxfAkd6KuSaoeZw',
    mapOrigin: [3.5241689477567775, 50.35778105235363]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
