import {Component, HostListener, OnInit} from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    innerWidth;
    mobile;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkWindowsSize();
    }

    constructor() {
    }

    ngOnInit() {
        this.checkWindowsSize();
    }

    checkWindowsSize() {
        this.innerWidth = window.innerWidth;
        this.mobile = this.innerWidth < 1430;
    }
}
