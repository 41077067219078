import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSidenav} from '@angular/material';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    innerWidth;
    mobile;
    home;

    @Input() nav: MatSidenav;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.checkWindowsSize();
    }

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.checkWindowsSize();
    }

    checkWindowsSize() {
        this.innerWidth = window.innerWidth;
        this.mobile = this.innerWidth < 1430;
    }

    redirect(URI: string, nav) {
        this.router.navigate([URI])
            .then(() => {
                if (nav) {
                    nav.close();
                }
            });
    }
}
