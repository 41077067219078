import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
    article = {
        id: 0,
        title: 'Title',
        subtitle: 'subtitle',
        text: 'text',
        text2: 'text',
        text3: 'text',
        text4: 'text',
        text5: 'text',
        text6: 'text'
    };

    constructor(private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit() {
        this.route.params
            .forEach((params) => {
                this.article.id = Number(params.id);
            });
    }

    public previousArticle() {
        this.router.navigate(['/news/' + (this.article.id - 1)]);
    }

    public nextArticle() {
        this.router.navigate(['/news/' + (this.article.id + 1)]);
    }

}
