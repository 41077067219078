import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private httpClient: HttpClient) {
    }


    changePassword(body, env) {
        console.log(env);
        const token = env === 'dev' ? environment.apiDevToken : environment.apiToken;
        env = env === 'dev' ? 'dev' : 'api';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            })
        };
        return this.httpClient.post(`https://${env}.jericoapp.fr/auth/updatepwd`, body, httpOptions).toPromise();
    }
}
