import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Points} from './Points';

@Injectable({
    providedIn: 'root'
})
export class ScootersService {

    constructor(private httpClient: HttpClient) {

    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + environment.apiToken
        })
    };

    getScooter() {
        return this.httpClient.get('https://api.jericoapp.fr/city/11/vehicles?type=scooter&state=free', this.httpOptions)
            .toPromise()
            .then((points: Points) => {
                const pointsGeo = { type: 'FeatureCollection',
                    features: []
                };
                // @ts-ignore
                for (const point of points) {
                    pointsGeo.features.push({ type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [point.coordinate.lon, point.coordinate.lat]
                        },
                        properties: {}
                    });
                }
                return pointsGeo;
            });
    }
}
