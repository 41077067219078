import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResetPasswordService} from './reset-password.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    password = '';
    passwordConfirmation = '';
    token: string;
    env: string;

    constructor(private route: ActivatedRoute, private router: Router, private resetPasswordService: ResetPasswordService) {
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.env = this.route.snapshot.queryParamMap.get('env');
    }

    ngOnInit() {
        console.log(this.token, this.env);
    }

    async changePassword() {
        if (this.password === this.passwordConfirmation) {
            try {
                await this.resetPasswordService.changePassword({password: this.password, token: this.token}, this.env);
                await this.router.navigate(['home']);
            } catch ({error}) {
                if (error.message === 'Data doesn\'t match the rules') {
                    alert('Le mot de passe doit contenir au moins 1 lettre minuscule, 1 lettre majuscule et 1 chiffre.');
                } else if (error.message === 'Expired reset password token') {
                    alert('Token expiré');
                } else if (error.message === 'Invalid reset password token') {
                    alert('Token invalide');
                }
            }
        }
    }
}
