import {Component, OnInit} from '@angular/core';
import mapboxgl from 'mapbox-gl';
import {environment} from '../../../environments/environment';
import {ScootersService} from './scooters.service';
import {Points} from './Points';

@Component({
    selector: 'app-scooters',
    templateUrl: './scooters.component.html',
    styleUrls: ['./scooters.component.scss']
})
export class ScootersComponent implements OnInit {

    map: mapboxgl;
    mapStyle = 'mapbox://styles/mapbox/streets-v10';
    points: Points;
    baseWidth = 1;
    baseZoom = 14;
    color: string;

    constructor(private mapService: ScootersService) {
    }

    ngOnInit() {
        this.getMapData();
    }

    private getMapData() {
        this.mapService.getScooter()
            .then((points: Points) => {
                this.points = points;
                this.initMap();
            });
    }

    private initMap() {
        mapboxgl.accessToken = environment.token;
        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.mapStyle,
            center: environment.mapOrigin,
            zoom: 15
        });
        this.map.on('load', () => {
            this.addSources();
            this.addLayers();
        });
    }

    private addSources() {
        this.map.addSource('points', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });
    }

    private addLayers() {
        this.map.loadImage('../../../assets/images/scooter.png', (error, image) => {
            if (error) {
                throw error;
            }
            this.map.addImage('cat', image);
            this.map.addLayer({
                id: 'points',
                type: 'symbol',
                source: 'points',
                layout: {
                    'icon-image': 'cat',
                    'icon-size': 0.75
                }
            });
            this.map.getSource('points').setData(this.points);
        });
    }

}
