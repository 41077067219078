import {Component} from '@angular/core';
import {AnimationItem, LottieOptions} from 'ngx-lottie';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jerico-web-front';
}
