import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {ActivitiesComponent} from './components/activities/activities.component';
import {SolutionsComponent} from './components/solutions/solutions.component';
import {ScootersComponent} from './components/scooters/scooters.component';
import {MobilityComponent} from './components/mobility/mobility.component';
import {ContactComponent} from './components/contact/contact.component';
import {BizAppComponent} from './components/biz-app/biz-app.component';
import {JericoAppComponent} from './components/jerico-app/jerico-app.component';
import {TransitAppComponent} from './components/transit-app/transit-app.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {PdfComponent} from './components/pdf/pdf.component';

const routes: Routes = [
    {
        path: 'policy',
        component: PdfComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'transit',
        component: TransitAppComponent
    },
    {
        path: 'mobility',
        component: MobilityComponent
    },
    {
        path: 'app',
        component: JericoAppComponent
    },
    {
        path: 'biz',
        component: BizAppComponent
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    // {
    //     path: 'team',
    //     component: TeamComponent
    // },
    // {
    //     path: 'news',
    //     component: NewsComponent,
    // },
    // {
    //     path: 'news/:id',
    //     component: ArticleComponent
    // },
    {
        path: 'scooters',
        component: ScootersComponent,
    },
    {
        path: 'solutions',
        component: SolutionsComponent
    },
    {
        path: 'activities',
        component: ActivitiesComponent
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
