import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import {ActivitiesComponent} from './components/activities/activities.component';
import {SolutionsComponent} from './components/solutions/solutions.component';
import {ScootersComponent} from './components/scooters/scooters.component';
import {NewsComponent} from './components/news/news.component';
import {TeamComponent} from './components/team/team.component';
import {ContactComponent} from './components/contact/contact.component';
import {ArticleComponent} from './components/news/article/article.component';
import {JericoAppComponent} from './components/jerico-app/jerico-app.component';
import {TransitAppComponent} from './components/transit-app/transit-app.component';
import {BizAppComponent} from './components/biz-app/biz-app.component';
import {LottieModule} from 'ngx-lottie';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatFormFieldModule, MatIconModule, MatSidenavModule, MatToolbarModule} from '@angular/material';
import { MobilityComponent } from './components/mobility/mobility.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {FormsModule} from '@angular/forms';
import { PdfComponent } from './components/pdf/pdf.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavbarComponent,
        FooterComponent,
        ActivitiesComponent,
        SolutionsComponent,
        ScootersComponent,
        NewsComponent,
        TeamComponent,
        ContactComponent,
        ArticleComponent,
        JericoAppComponent,
        TransitAppComponent,
        BizAppComponent,
        MobilityComponent,
        ResetPasswordComponent,
        PdfComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LottieModule,
        HttpClientModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        FormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
